<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <span class="layout-topbar-user-name"> {{ $auth.user.nomecompleto }} </span>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="toggleMenu">
      <i class="pi pi-user"></i>
    </button>
    <Menu
      ref="profilemenu"
      class="profile-menu-item-custom"
      :model="items"
      :popup="true" />
  </div>
</template>

<script>
import Config from '@/config'

export default {
  data() {
    return {
      items: [],
    }
  },

  created() {
    this.preencheItens()
  },

  methods: {
    preencheItens() {
      const itemsBase = [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ]
      if (Config.HABILITA_BOTAO_2FA) {
        const item = {
          label: 'Autenticação de dois fatores',
          command: () => {
            window.location.href =
              Config.REALM_PB +
              '/protocol/openid-connect/auth?client_id=' +
              Config.CLIENT_ID +
              '&redirect_uri=' +
              window.location.origin +
              window.location.pathname +
              '&response_type=code&scope=openid&kc_action=CONFIGURE_TOTP'
          },
        }
        itemsBase.splice(1, 0, item)
      }
      this.items = itemsBase
    },

    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },

    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
  },
}
</script>

<style>
.profile-menu-item-custom span {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
