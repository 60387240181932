<template>
  <div>
    <div :class="containerClass" @click="onWrapperClick">
      <div class="layout-sidebar" @click="onSidebarClick">
        <div class="layout-logo-wrapper flex items-center relative">
          <router-link
            to="/"
            class="layout-logo-text absolute left-1/2 transform -translate-x-1/2">
            <span>GESTÃO</span>
          </router-link>
          <div class="notification-btn-wrapper absolute right-0">
            <Button
              icon="pi pi-bell"
              class="notification-btn"
              @click="toggleOverlay" />
            <span v-show="ultimaMensagem" class="badge">1</span>
          </div>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>

      <AppTopBar :class="topBarClass" @menu-toggle="onMenuToggle" />
      <div class="layout-main-container">
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
      </div>

      <transition name="layout-mask">
        <div
          v-if="mobileMenuActive"
          class="layout-mask p-component-overlay"></div>
      </transition>
    </div>
    <div>
      <OverlayPanel v-if="ultimaMensagem" ref="op">
        <div
          v-for="(mensagem, index) in ultimaMensagem"
          :key="index"
          class="flex flex-column gap-3 w-25rem">
          <p><strong>Aviso Global:</strong> {{ mensagem.mensagem }}</p>
        </div>
      </OverlayPanel>
    </div>
    <div>
      <Dialog
        v-if="ultimaMensagem"
        :visible.sync="dialogVisible"
        :modal="true"
        :closable="true"
        :style="{ width: '45rem' }">
        <div v-for="(mensagem, index) in ultimaMensagem" :key="index">
          <p><strong>Aviso Global:</strong> {{ mensagem.mensagem }}</p>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import AppTopbar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'
import MensagemService from '@/service/MensagemService.js'
import auth from '@/modules/auth'

export default {
  components: {
    AppTopBar: AppTopbar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  data() {
    return {
      dialogVisible: false,
      ultimaMensagem: null,
      layoutMode: 'static',
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: [],
      atualizandoToken: false,
    }
  },

  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },

    topBarClass() {
      return [
        {
          'layout-topbar-responsive':
            this.staticMenuInactive && this.layoutMode === 'static',
        },
      ]
    },
  },

  async created() {
    this.setaPerfil()
    const service = new MensagemService(this.$http)
    try {
      this.ultimaMensagem = await service.buscarUltimaMensagem(this.perfil)
      this.dialogVisible = true
    } catch (error) {
      console.error('Erro ao buscar última mensagem:', error)
      // Tratar erro conforme necessário
    }
    this.ativarTimerParaAtualizarToken()
    if (this.tokenExpirado()) {
      this.logout()
    }

    if (auth.hasRoleGestaoEntidade()) {
      this.menu = [
        {
          label: 'Home',
          items: [
            {
              label: 'Servidores',
              icon: 'pi pi-fw pi-users',
              to: { name: 'servidores' },
            },
          ],
        },
        {
          label: 'Sistema',
          icon: 'pi pi-fw pi-sitemap',
          items: [
            {
              label: 'Consignatárias',
              icon: 'pi pi-fw pi-wallet',
              to: { name: 'consignatarias' },
            },
            {
              label: 'Rubricas',
              icon: 'pi pi-fw pi-pencil',
              to: { name: 'rubricas' },
            },
            {
              label: 'Arquivo',
              icon: 'pi pi-fw pi-sitemap',
              items: [
                // {
                //   label: 'Importar Arquivo',
                //   icon: 'pi pi-fw pi-cloud-upload',
                //   to: { name: 'importacoes' },
                // },
                {
                  label: 'Gerar Arquivo Movimento',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'gerar-arquivo-movimento' },
                },
              ],
            },
            {
              label: 'Relatórios',
              icon: 'pi pi-fw pi-print',
              to: { name: 'relatorio' },
            },
            {
              label: 'Perfis',
              icon: 'pi pi-tag',
              items: [
                {
                  label: 'Perfis Usuários',
                  icon: 'pi pi-users',
                  to: { name: '' },
                },
                {
                  label: 'Grupos',
                  icon: 'pi pi-globe',
                  to: { name: '' },
                },
                {
                  label: 'Roles',
                  icon: 'pi pi-tag',
                  to: { name: '' },
                },
              ],
            },
          ],
        },
      ]
    }

    if (auth.hasRoleGestao()) {
      this.menu = [
        {
          label: 'Home',
          items: [
            {
              label: 'Servidores',
              icon: 'pi pi-fw pi-users',
              to: { name: 'servidores' },
            },
          ],
        },
        {
          label: 'Sistema',
          icon: 'pi pi-fw pi-sitemap',
          items: [
            {
              label: 'Consignatárias',
              icon: 'pi pi-fw pi-wallet',
              to: { name: 'consignatarias' },
            },
            {
              label: 'Entidades',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'entidades' },
            },
            {
              label: 'Atividades',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'atividades' },
            },
            {
              label: 'Órgãos',
              icon: 'pi pi-fw pi-building',
              to: { name: 'orgaos' },
            },
            {
              label: 'Rubricas',
              icon: 'pi pi-fw pi-pencil',
              to: { name: 'rubricas' },
            },
            {
              label: 'Vínculos',
              icon: 'pi pi-fw pi-link',
              to: { name: 'vinculos' },
            },
            {
              label: 'Grupos',
              icon: 'pi pi-fw pi-sitemap',
              to: { name: 'grupos' },
            },
            {
              label: 'Financeiro',
              icon: 'pi pi-fw pi-dollar',
              items: [
                {
                  label: 'Operações de Finanças',
                  icon: 'pi pi-fw pi-dollar',
                  to: { name: 'financas' },
                },
                {
                  label: 'Tabela de Precificação',
                  icon: 'pi pi-fw pi-money-bill',
                  to: { name: 'tabela-precificacao' },
                },
              ],
            },
            {
              label: 'Configurações',
              icon: 'pi pi-fw pi-cog',
              items: [
                {
                  label: 'Variáveis de Margem',
                  icon: 'pi pi-fw pi-chart-line',
                  to: { name: 'configuracoes' },
                },
                {
                  label: 'Painel de Mensagens',
                  icon: 'pi pi-fw pi-envelope',
                  to: { name: 'nova-mensagem' },
                },
                {
                  label: 'Ajuste de Parâmetros',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'ajuste-parametros' },
                },
              ],
            },
            {
              label: 'Usuários',
              icon: 'pi pi-user',
              to: { name: 'usuarios' },
            },
            // {
            //   label: 'Config. perfil de usuário',
            //   icon: 'pi pi-fw pi-user',
            //   to: { name: 'configuracao-perfil' },
            // },
            // {
            //   label: 'Permissões',
            //   icon: 'pi pi-fw pi-globe',
            //   to: { name: 'permissoes' },
            // },
            {
              label: 'Arquivo',
              icon: 'pi pi-fw pi-sitemap',
              items: [
                {
                  label: 'Importar Arquivo',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'importar-arquivo' },
                },
                {
                  label: 'Gerar Arquivo Movimento',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'gerar-arquivo-movimento' },
                },
                // {
                //   label: 'Exportação de Arquivo',
                //   icon: 'pi pi-fw pi-cloud-download',
                //   to: { name: 'exportacoes' },
                // },
              ],
            },
            {
              label: 'Relatórios',
              icon: 'pi pi-fw pi-print',
              to: { name: 'relatorio' },
            },
            {
              label: 'Perfis',
              icon: 'pi pi-tag',
              items: [
                {
                  label: 'Perfis Usuários',
                  icon: 'pi pi-users',
                  to: { name: 'perfilSeguranca' },
                },
                {
                  label: 'Grupos',
                  icon: 'pi pi-globe',
                  to: { name: 'grupoSeguranca' },
                },
                {
                  label: 'Roles',
                  icon: 'pi pi-tag',
                  to: { name: 'role' },
                },
              ],
            },
          ],
        },
        // {
        //   label: 'Usuários',
        //   items: [
        //     {
        //       label: 'Gestão-Entidade',
        //       icon: 'pi pi-user',
        //       to: { name: 'usuarios' },
        //     },
        //     {
        //       label: 'Consignatárias',
        //       icon: 'pi pi-wallet',
        //       to: { name: 'usuariosconsignatarias' },
        //     },
        //     // {
        //     //   label: 'Keycloak',
        //     //   icon: 'pi pi-key',
        //     //   to: { name: 'keycloak-usuarios' },
        //     // },
        //   ],
        // },
      ]
    }

    if (auth.hasRoleGestaoFinanceira()) {
      this.menu = [
        {
          label: 'Sistema',
          icon: 'pi pi-fw pi-sitemap',
          items: [
            /*            {
              label: 'Consignatárias',
              icon: 'pi pi-fw pi-wallet',
              to: { name: 'consignatarias' },
            },
            {
              label: 'Entidades',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'entidades' },
            },
            {
              label: 'Órgãos',
              icon: 'pi pi-fw pi-building',
              to: { name: 'orgaos' },
            },*/
            {
              label: 'Finanças',
              icon: 'pi pi-fw pi-dollar',
              to: { name: 'financas' },
            },
            {
              label: 'Relatórios',
              icon: 'pi pi-fw pi-print',
              to: { name: 'relatorio' },
            },
          ],
        },
      ]
    }
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    toggleDialogVisibility() {
      this.dialogVisible = !this.dialogVisible
    },
    setaPerfil() {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      let subdomain
      if (parts[0] === 'dev') {
        subdomain = parts[1]
      } else {
        subdomain = parts[0]
      }
      switch (subdomain) {
        case 'gestao':
          this.perfil = 'gestao'
          this.perfilLabel = 'Gestão'
          break
        case 'consignataria':
          this.perfil = 'consignataria'
          this.perfilLabel = 'Consignatária'
          break
        case 'servidor':
          this.perfil = 'servidor'
          this.perfilLabel = 'Servidor'
          break
        default:
          this.perfil = 'servidor'
          this.perfilLabel = 'Servidor'
          break
      }
    },
    toggleOverlay(event) {
      this.$refs.op.toggle(event)
    },
    tempoRestante() {
      return this.$auth.getParsedToken().exp - Date.now() / 1000
    },

    tokenExpirado() {
      return this.tempoRestante() < 0
    },

    logout() {
      clearInterval(this.timer)
      this.$auth.logout()
      window.location.reload()
    },

    ativarTimerParaAtualizarToken() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.tokenExpirado()) {
            this.logout()
          } else if (this.tempoRestante() < 140) {
            this.$auth.refreshToken()
          }
        }, 70000)
      }
    },

    isDesktop() {
      return window.innerWidth >= 992
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
      this.menuClick = false
    },

    onSidebarClick() {
      this.menuClick = true
      this.overlayMenuActive = false
      this.mobileMenuActive = false
    },

    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
  },
}
</script>

<style>
.notification-btn-wrapper {
  margin-top: 1.5rem;
  margin-right: -0.5rem;
}
.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
}
.notification-btn {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
